<style lang="scss">
#frontend {
  .marken-box-outer {
    .grid-container {
      justify-content: center;
      position: relative;
      transition: all 200ms ease;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px;
      grid-template-rows: 100px 100px 100px 100px 100px 100px;
      grid-gap: 10px;
      grid-template-areas:
        '. . . box1 . . box3 box3 . . . .'
        '. . . . box2 . box3 box3 . box13 . .'
        '. . . . box15 box15 . . . box9 box9 .'
        '. . box6 box6 . . box7 box7 . box9 box9 .'
        '. . box6 box6 . box16 box16 . box14 box14 . .'
        '. box12 box12 . . . . . box14 box14 . .';

      @media (max-width: 1300px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
        grid-template-areas:
          '. . . box1 . box3 box3 . . .'
          '. . . . box2 box3 box3 box12 box12 .'
          '. . box6 box6 box7 box7 box16 box16 . .'
          '. . box6 box6  . box9 box9 box15 box15 .'
          '. . . . . box9 box9 box13 . .'
          '. . . . . . . . box14 .';
      }

      @media (max-width: $width-lg) {
        grid-template-columns: 100px 100px 100px 100px 100px 100px;
        grid-template-rows: 100px 100px 100px 100px 100px 100px;
        grid-template-areas:
          'box12 box12 . . box3 box3'
          'box1 . box15 box15 box3 box3'
          '. box9 box9 box16 box16 box13'
          '. box9 box9 box14 box7 box7'
          '. . . box6 box6 .'
          '. . . box6 box6 box2';
      }

      @media (max-width: $width-md) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-content: center;
        grid-gap: 0;
        grid-template-areas:
          'box1 box2'
          'box3 box7'
          'box6 box12'
          'box9 box15'
          'box13 box14'
          'box16 .';
      }
    }

    .grid-container-v2 {
      justify-content: center;
      position: relative;
      transition: all 200ms ease;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px;
      grid-template-rows: 100px 100px 100px 100px 100px 100px;
      grid-gap: 10px;
      grid-template-areas:
        '. . . box14 box14 .  box9 box9 . . . .'
        '. . box7 box7  . .  box9 box9 . . . .'
        '. . . box12 box12 . . . . . . .'
        '. . . box12 box12 box17 box17 . box5 box5 . .'
        '. . box6 box6 . box13 box13 . . . . .'
        '. . box6 box6 . box13 box13 box10 box10 . . .';

      @media (max-width: 1300px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
        grid-template-areas:
          '. . . box10 box10  . . . . .'
          '. . box12 box12 . . . . . .'
          '. . box12 box12 . box7 box7 box9 box9  .'
          '. box17 box17 box5 box5 . . box9 box9  . '
          '. box14 box14 . box13 box13 . box6 box6  . '
          '. . . . box13 box13 . . . . ';
      }

      @media (max-width: $width-lg) {
        grid-template-columns: 100px 100px 100px 100px 100px 100px;
        grid-template-rows: 100px 100px 100px 100px 100px 100px;
        grid-template-areas:
          'box13 box13 . . box14 box14'
          'box13 box13 box12 box12 box9 box9'
          'box10 box10 box12 box12 box9 box9 '
          'box5 box5 . box7 box7 .'
          'box5 box5 box17 box17 . .'
          '. . . box6 box6 .';
      }

      @media (max-width: $width-md) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-content: center;
        grid-gap: 0;
        grid-template-areas:
          'box5 box6'
          'box7 box13'
          'box9 box10'
          'box14 box17'
          '. box12';

      }
    }

    .grid-container-v3 {
      justify-content: center;
      position: relative;
      transition: all 200ms ease;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px;
      grid-template-rows: 100px 100px 100px 100px 100px 100px;
      grid-gap: 10px;
      grid-template-areas:
        '. . . box4 box4 . box2 box2  . . . .'
        '. . box5 box5 . . box2 box2 .  box3 box3  .'
        '. . . box15 box15 . box1 . . . . .'
        '. . . box15 box15 . . .  box6 . . .'
        '. . box12 box12 . . box9 box9 . . box10 .'
        '. box8 box12 box12 . . box9 box9 . . . .';

      @media (max-width: 1300px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
        grid-template-areas:
          '. . . box5 box5 . box2 box2 . .'
          '. . box1 . box3 box3 box2 box2 . .'
          '. box6 box6 . . . . box9 box9 .'
          '. box6 box6 box4 box4 box8 . box9 box9 .'
          '. . box10 box15 box15 . box12 box12 . .'
          '. . . box15 box15 . box12 box12 . .';
      }

      @media (max-width: $width-lg) {
        grid-template-columns: 100px 100px 100px 100px 100px 100px;
        grid-template-rows: 100px 100px 100px 100px 100px 100px;
        grid-template-areas:
          'box3 box3 box1 . box9 box9'
          '. . . . box9 box9'
          'box2 box2 box5 box5 . . '
          'box2 box2 . box12 . box8 '
          'box15 . . box6 box6 box10'
          '. box4 box4 box6 box6 .';
      }

      @media (max-width: $width-md) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-content: center;
        grid-gap: 0;
        grid-template-areas:
          'box1 box2'
          'box3 box4'
          'box5 box6'
          'box15 box8'
          'box9 box10'
          'box12 .';
      }
    }

    .grid-container-v4 {
      justify-content: center;
      position: relative;
      transition: all 200ms ease;
      width: 100%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px 100px;
      grid-template-rows: 100px 100px 100px 100px 100px 100px;
      grid-gap: 10px;
      grid-template-areas:
        '. . . box6 box6 . . box3 box3 . . .'
        '. . . . . box7 box7 box3 box3 . . .'
        '. . box12 box12 . box7 box7 . box2 box2 . .'
        '. . . . . . . box11 box11 . . . '
        '. box5 box5 . box13 box13 . box11 box11 . . .'
        '. . . . box13 box13 . . box14 box14 . .';

      @media (max-width: 1300px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
      }

      @media (max-width: 1200px) {
        grid-template-columns: 90px 90px 90px 90px 90px 90px 90px 90px 90px 90px;
        grid-template-rows: 90px 90px 90px 90px 90px 90px;
        grid-template-areas:
          '. . . box3 .  . box2 box2 . .'
          '. . box6 box6 box11 box11 . . . .'
          '. . . . box11 box11  box14 box14 . .'
          '. . . box13 box13 . . .  . .'
          '. box7 box7 box13 box13 . . box5 box5  .'
          '. . . . . box12 box12 . . .';
      }

      @media (max-width: $width-lg) {
        grid-template-columns: 100px 100px 100px 100px 100px 100px;
        grid-template-rows: 100px 100px 100px 100px 100px 100px;
        grid-template-areas:
          '. box2 box2 . box14 box14'
          '. . box7 box7 . .'
          'box11 box11 . . box5 box5'
          'box11 box11 box13 box13 . .'
          '. . box13 box13 box12 box12'
          'box6 box6 . . box3 .';
      }

      @media (max-width: $width-md) {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto;
        justify-content: center;
        grid-gap: 0;
        grid-template-areas:
          'box13 box2'
          'box5 box12'
          'box7 box6'
          'box11 box14'
          'box3 .';
      }
    }

    .outer-box {
      @media (max-width: $width-sm) {
        width: 100%;
        margin: 0 auto;
      }

      @media (max-width: 400px) {
        width: 100%;
      }

      .inner-box {
        position: relative;
        width: 100%;
        height: 100%;

        //background:#ededed;
        @media (max-width: $width-md) {
          width: 100%;
          height: 230px;
          margin: 0 auto;
          border: 1px solid #fff;
          //border:2px solid red !important;
        }

        @media (max-width: $width-sm) {
          width: 100%;
          height: 180px;
          margin: 0 auto;
          border: 1px solid #fff;
        }

        a {
          //border:1px solid red;
          display: block;
          position: relative;
          height: 100%;
          transition: $transition-std;

          img {
            padding: 0 10px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            display: block;
            margin: 0 auto;

            @media (max-width: $width-md) {
              width: 70%;
              display: block;
              margin: 0 auto;
            }
          }

          .img-smaller {
            @media (max-width: $width-md) {
              width: 50%;
            }
          }

          .img-smaller-v2 {

            @media (max-width: $width-lg) {
              width: 90%;
            }

            @media (max-width: $width-md) {
              width: 60%;
            }

            @media (max-width: $width-sm) {
              width: 70%;
            }

          }

          .img-kiz-klz,
          .img-rca {
            @media (max-width: $width-xl) {
              padding: 0 30px;
            }
          }

          &:hover {
            transform: scale(0.9);
          }
        }

        .padding-30 {
          padding: 0 30px;

          @media (max-width: $width-md) {
            padding: 0 15px;
          }
        }

        .padding-15 {
          padding: 0 15px;
        }
      }

      $alpha-wert: 0.3;

      .bg-blue-60per {
        background-color: rgba($color: $blue-60per, $alpha: $alpha-wert);
      }

      .bg-mint {
        background-color: rgba($color: $mint, $alpha: $alpha-wert);
      }

      .bg-violet-50per {
        background-color: rgba($color: $violet-50per, $alpha: $alpha-wert);
      }

      .bg-sand {
        background-color: rgba($color: $sand, $alpha: $alpha-wert);
      }

      .bg-grey {
        background-color: rgba($color: $grey, $alpha: $alpha-wert);
      }

      .bg-wine-red {
        background-color: rgba($color: $wine-red, $alpha: $alpha-wert);
      }

      .bg-yellow {
        background-color: rgba($color: $yellow, $alpha: $alpha-wert);
      }

      .bg-sand {
        background-color: rgba($color: $sand, $alpha: $alpha-wert);
      }

      .bg-joom-boos {
        background-color: rgba($color: #f7ae11, $alpha: $alpha-wert);
      }

      .bg-klz {
        background-color: rgba($color: #c50d0f, $alpha: $alpha-wert);
      }

      .bg-antenne {
        background-color: rgba($color: #ffed00, $alpha: $alpha-wert);
      }

      .bg-wienerin {
        background-color: rgba($color: #c71565, $alpha: $alpha-wert);
      }

      .bg-presse {
        background-color: rgba($color: #1a233b, $alpha: $alpha-wert);
      }

      .bg-furche {
        background-color: rgba($color: #931813, $alpha: $alpha-wert);
      }

      .bg-willhaben {
        background-color: rgba($color: #00a9e9, $alpha: $alpha-wert);
      }

      .bg-ligaportal {
        background-color: rgba($color: #005ea7, $alpha: $alpha-wert);
      }

      .bg-24sata {
        background-color: rgba($color: #cd1719, $alpha: $alpha-wert);
      }

      .bg-vercernji {
        background-color: rgba($color: #e63f39, $alpha: $alpha-wert);
      }

      .bg-ballguide {
        background-color: rgba($color: #cead72, $alpha: $alpha-wert);
      }

      .bg-bolha {
        background-color: rgba($color: #f0b501, $alpha: $alpha-wert);
      }

      .bg-grazer {
        background-color: rgba($color: #f0b501, $alpha: $alpha-wert);
      }

      .bg-connect724 {
        background-color: rgba($color: #d70a27, $alpha: $alpha-wert);
      }

      .border-coral {
        border: 5px solid rgba($color: $coral, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-blue {
        border: 5px solid rgba($color: $blue, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-blue-60per {
        border: 5px solid rgba($color: $blue-60per, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-mint {
        border: 5px solid rgba($color: $mint, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-violet-50per {
        border: 5px solid rgba($color: $violet-50per, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-sand {
        border: 5px solid rgba($color: $sand, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-grey {
        border: 5px solid rgba($color: #ccc, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-wine-red {
        border: 5px solid rgba($color: $wine-red, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-yellow {
        border: 5px solid rgba($color: $yellow, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }

      .border-sand {
        border: 5px solid rgba($color: $sand, $alpha: $alpha-wert);

        @media (max-width: $width-md) {
          border: 0;
        }
      }
    }

    .box1 {
      grid-area: box1;
    }

    .box2 {
      grid-area: box2;
    }

    .box3 {
      grid-area: box3;
    }

    .box4 {
      grid-area: box4;
    }

    /*
      .box4 {
        grid-area: box4;
        @media (max-width: $width-md) {
          display: none;
        }
      }*/
    .box5 {
      grid-area: box5;
    }

    .box6 {
      grid-area: box6;
    }

    .box7 {
      grid-area: box7;
    }

    .box8 {
      grid-area: box8;
    }

    .box9 {
      grid-area: box9;
    }

    .box10 {
      grid-area: box10;
    }

    .box11 {
      grid-area: box11;
    }

    .box12 {
      grid-area: box12;
    }

    .box13 {
      grid-area: box13;
    }

    .box14 {
      grid-area: box14;
    }

    .box15 {
      grid-area: box15;
    }

    .box16 {
      grid-area: box16;
    }

    .box17 {
      grid-area: box17;
    }

    .container-marken {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
    }

    .slider-outer-marken {
      //border:1px solid red;
      padding-top: 80px;
      padding-bottom: 80px;
      position: relative;

      .arrow-outer {
        width: 100%;
        max-width: 1280px;
        margin: 0 auto;

        .arrow-all {
          position: absolute;
          height: 50px;
          width: 50px;

          cursor: pointer;

          img {
            display: block;
            max-width: 100%;
          }

          &:hover {
            opacity: 0.7;
          }
        }

        .arrow-left {
          top: 0;
          left: 30.5%;

          @media (max-width: $width-lg) {
            left: 30%;
            margin-left: -25px;
          }
        }

        .arrow-right {
          right: 30.5%;
          top: 0;

          @media (max-width: $width-lg) {
            right: 30%;
            margin-right: -25px;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="marken-box-outer">
    <div class="container-marken">
      <div class="slider-outer-marken">
        <Slider :delay="0" :items="1" easing="ease-in-out" :speed="1500">
          <template #navigation="{ left, right }">
            <div class="arrow-outer">
              <div @click="right" class="arrow-all arrow-left"><img src="@/assets/img/slider-arrow-left.svg"
                  alt="Pfeil Links" class="img-fluid" /></div>
              <div @click="left" class="arrow-all arrow-right"><img src="@/assets/img/slider-arrow-right.svg"
                  alt="Pfeil Rechts" class="img-fluid" /></div>
            </div>
          </template>
          <template #items>
            <SliderItem>
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="grid-container">
                    <div class="outer-box box2">
                      <div class="inner-box border-sand" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'joomboos' }">
                          <img src="@/assets/img/marken/brands/joom-boos.svg" alt="Joob Boos" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box3">
                      <div class="inner-box border-yellow" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'antenne-steiermark' }">
                          <img src="@/assets/img/marken/brands/antenne-stmk-btn.svg" alt="Radio Antenne"
                            class="img-fluid padding-30 img-smaller " />
                        </router-link>
                      </div>
                    </div>

                    <div class="outer-box box6">
                      <div class="inner-box border-wine-red" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'kleine-zeitung' }">
                          <img src="@/assets/img/marken/brands/klz-logo.svg" alt="Kleine Zeitung"
                            class="img-fluid padding-30" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box7">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'sport-aktiv' }">
                          <img src="@/assets/img/marken/brands/sportaktiv.svg" alt="Sport Aktiv"
                            class="img-fluid padding-30" />
                        </router-link>
                      </div>
                    </div>

                    <div class="outer-box box9">
                      <div class="inner-box border-mint" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'die-presse' }">
                          <img src="@/assets/img/marken/brands/die-presse.svg" alt="Die Presse" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>

                    <div class="outer-box box12">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'ligaportal' }">
                          <img src="@/assets/img/marken/brands/ligaportal.svg" alt="Ligaportal" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box13">
                      <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'molden-verlag' }">
                          <img src="@/assets/img/marken/brands/molden.svg" alt="Molden Verlag"
                            class="img-fluid img-smaller " />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box14">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'express' }">
                          <img src="@/assets/img/marken/brands/express.svg" alt="Express" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box15">
                      <div class="inner-box border-grey" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'kneipp-verlag' }">
                          <img src="@/assets/img/marken/brands/kneipp-verlag.svg" alt="Kneipp Verlag" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box16">
                      <div class="inner-box border-grey" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'pharmonia' }">
                          <img src="@/assets/img/marken/brands/pharmonia-logo.svg" alt="pharmonia" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="grid-container-v2">
                    <div class="outer-box box5">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'der-grazer' }">
                          <img src="@/assets/img/marken/brands/der-grazer.svg" alt="der Grazer" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box6">
                      <div class="inner-box border-violet-50per" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'kleine-kinderzeitung' }">
                          <img src="@/assets/img/marken/brands/kinderzeitung.svg" alt="Kleine Kinderzeitung"
                            class="img-fluid img-kiz-klz" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box7">
                      <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'meinbezirk' }">
                          <img src="@/assets/img/marken/brands/meinbezirk.webp" alt="meinbezirk.at" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <!--
                          <div class="outer-box box8">
                            <div class="inner-box border-mint" v-inview="['animation-delay-1s', 'scale-in']">
                              <router-link :to="{ name: 'futter' }">
                                <img src="@/assets/img/marken/brands/futter.svg" alt="Futter" class="img-fluid" />
                              </router-link>
                            </div>
                          </div>
                        -->
                    <div class="outer-box box9">
                      <div class="inner-box border-mint" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'vecernji-list' }">
                          <img src="@/assets/img/marken/brands/vecernji-list.svg" alt="vecernji-list"
                            class="img-fluid " />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box10">
                      <div class="inner-box border-violet-50per" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'miss7mama' }">
                          <img src="@/assets/img/marken/brands/missmama-logo.svg" alt="Miss 7 Mama" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>

<!--                    <div class="outer-box box12">-->
<!--                      <div class="inner-box border-sand" v-inview="['animation-delay-1-5s', 'scale-in']">-->
<!--                        <router-link :to="{ name: 'woche' }">-->
<!--                          <img src="@/assets/img/marken/brands/woche.svg" alt="WOCHE" class="img-fluid" />-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="outer-box box13">
                      <div class="inner-box border-wine-red" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: '24sata' }">
                          <img src="@/assets/img/marken/brands/24sata.svg" alt="24sata" class="img-fluid img-smaller" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box14">
                      <div class="inner-box border-wine-red" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'med-over-net' }">
                          <img src="@/assets/img/marken/brands/med-over-net.svg" alt="med.over.net" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box17">
                      <div class="inner-box border-grey" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'adriasonara' }">
                          <img src="@/assets/img/marken/brands/adirasonara.jpg" alt="adriasonara" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="grid-container-v3">
                    <div class="outer-box box1">
                      <div class="inner-box border-sand" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'regional-medien-gesundheit' }">
                          <img src="@/assets/img/marken/brands/regional-medien-gesundheit.webp" alt="Regional Medien Gesundheit"
                            class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box2">
                      <div class="inner-box border-yellow" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'antenne-kaernten' }">
                          <img src="@/assets/img/marken/brands/antenne-ktn-btn.svg" alt="Antenne Kärnten"
                            class="img-fluid padding-30 img-smaller " />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box3">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'poslovni-dnevnik' }">
                          <img src="@/assets/img/marken/brands/poslovni.svg" alt="Poslovni Dnevnik" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box4">
                      <div class="inner-box border-sand" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'pixsell' }">
                          <img src="@/assets/img/marken/brands/pixsell.svg" alt="Pixsell" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box5">
                      <div class="inner-box border-violet-50per" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'miss7gastro' }">
                          <img src="@/assets/img/marken/brands/miss7_gastro.svg" alt="Miss 7 Gastro" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box6">
                      <div class="inner-box border-violet-50per" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'kleine-pausenzeitung' }">
                          <img src="@/assets/img/marken/brands/pausenzeitung-v2.png" alt="Pausenzeitung"
                            class="img-fluid" />
                        </router-link>
                      </div>
                    </div>

<!--                    <div class="outer-box box8">-->
<!--                      <div class="inner-box border-mint" v-inview="['animation-delay-1s', 'scale-in']">-->
<!--                        <router-link :to="{ name: 'bezirksblaetter' }">-->
<!--                          <img src="@/assets/img/marken/brands/bezirksblaetter.svg" alt="Bezirksblätter"-->
<!--                            class="img-fluid" />-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="outer-box box9">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'cope' }">
                          <img src="@/assets/img/marken/brands/cope-group.svg" alt="Cope Group"
                            class="img-fluid padding-15" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box10">
                      <div class="inner-box border-grey" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'styria-verlag' }">
                          <img src="@/assets/img/marken/brands/styria-verlag.svg" alt="Styria Verlag" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>

<!--                    <div class="outer-box box12">-->
<!--                      <div class="inner-box border-sand" v-inview="['animation-delay-1-5s', 'scale-in']">-->
<!--                        <router-link :to="{ name: 'regional-medien-gesundheit' }">-->
<!--                          <img src="@/assets/img/marken/brands/hausaerztin.svg" alt="Hausärztin" class="img-fluid" />-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </div>-->

<!--                    <div class="outer-box box15">-->
<!--                      <div class="inner-box border-mint" v-inview="['animation-delay-1s', 'scale-in']">-->
<!--                        <router-link :to="{ name: 'wiener-bezirkszeitung' }">-->
<!--                          <img src="@/assets/img/marken/brands/wiener-bezirkszeitung.svg" alt="RCA"-->
<!--                            class="img-fluid padding-15" />-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </div>
              </div>
            </SliderItem>
            <SliderItem>
              <div class="row no-gutters">
                <div class="col-12">
                  <div class="grid-container-v4">
                    <div class="outer-box box2">
                      <div class="inner-box border-sand" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'die-furche' }">
                          <img src="@/assets/img/marken/brands/die-furche.png" alt="Die Furche" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
<!--                    <div class="outer-box box3">-->
<!--                      <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">-->
<!--                        <router-link :to="{ name: 'leben' }">-->
<!--                          <img src="@/assets/img/marken/brands/spittaler-leben.svg" alt="Spittaler Leben"-->
<!--                            class="img-fluid" />-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </div>-->

                    <div class="outer-box box5">
                      <div class="inner-box border-violet-50per" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'miss7mama' }">
                          <img src="@/assets/img/marken/brands/missmama-logo.svg" alt="Miss 7 Mama" class="img-fluid" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box6">
                      <div class="inner-box border-wine-red" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'miss7zdrava' }">
                          <img src="@/assets/img/marken/brands/miss-zdrava-logo.svg" alt="Miss Zdrava"
                            class="img-fluid " />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box7">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'redmail' }">
                          <img src="@/assets/img/marken/brands/redmail.svg" alt="Redmail" class="img-fluid padding-30" />
                        </router-link>
                      </div>
                    </div>

                    <div class="outer-box box11">
                      <div class="inner-box border-violet-50per" v-inview="['animation-delay-1s', 'scale-in']">
                        <router-link :to="{ name: 'radio-flamingo' }">
                          <img src="@/assets/img/marken/brands/radio-flamingo.svg" alt="Radio Flamingo"
                            class="img-fluid padding-15" />
                        </router-link>
                      </div>
                    </div>
                    <div class="outer-box box12">
                      <div class="inner-box border-blue-60per" v-inview="['animation-delay-1-5s', 'scale-in']">
                        <router-link :to="{ name: 'pichler-verlag' }">
                          <img src="@/assets/img/marken/brands/pichler.svg" alt="Pichler Verlag"
                            class="img-fluid padding-30" />
                        </router-link>
                      </div>
                    </div>
<!--                    <div class="outer-box box13">-->
<!--                      <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">-->
<!--                        <router-link :to="{ name: 'regional-medien-gesundheit' }">-->
<!--                          <img src="@/assets/img/marken/brands/meinmed.svg" alt="meinmed.at"-->
<!--                            class="img-fluid img-smaller " />-->
<!--                        </router-link>-->
<!--                      </div>-->
<!--                    </div>-->
                    <div class="outer-box box14">
                      <div class="inner-box border-mint" v-inview="['animation-delay-0-5s', 'scale-in']">
                        <router-link :to="{ name: 'zurnal24' }">
                          <img src="@/assets/img/marken/brands/zurnal24.svg" alt="zurnal24.si"
                            class="img-fluid img-smaller " />
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SliderItem>
          </template>
        </Slider>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import Slider from '../Slider.vue';
import SliderItem from '../SliderItem.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    Slider,
    SliderItem,
  },
  setup() {
    const router = useRouter();
    const items = ref(1);
    return {
      items,
    };
  },
});
</script>
